import React from "react"

import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid"

export default function Index() {
  const { t } = useTranslation()
  const { language } = useI18next()

  return (
    <Layout withPattern={false}>
      {/* Hero section */}
      <Seo
        title={t("privacy_title")}
        lang={language}
        description={t("meta_description")}
      />
      <div className="relative px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-primary-700">
            Dernière mise à jour: 10/04/2023
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Politique de confidentialité du site GEERD
          </h1>
          <p className="mt-6 text-xl leading-8">
            Nous prenons très au sérieux la protection de votre vie privée.
            Cette politique de confidentialité décrit la manière dont nous
            collectons, utilisons et protégeons les informations personnelles
            que vous nous fournissez sur notre site web.
          </p>
          <div className="mt-10 max-w-2xl">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Collecte d'informations personnelles
            </h2>
            <p className="mt-6">
              Nous pouvons collecter des informations personnelles telles que
              votre nom, votre adresse e-mail, votre adresse postale ou votre
              numéro de téléphone lorsque vous vous inscrivez sur notre site
              web, vous abonnez à notre newsletter, remplissez un formulaire de
              contact ou passez une commande.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Utilisation d'informations personnelles{" "}
            </h2>
            <p className="mt-6">
              Nous utilisons les informations personnelles que vous nous
              fournissez pour vous fournir les produits, services ou
              informations que vous avez demandés, pour répondre à vos questions
              ou pour vous contacter au sujet de votre commande. Nous pouvons
              également utiliser vos informations pour vous envoyer des
              courriels promotionnels ou des newsletters si vous vous êtes
              inscrit à notre liste de diffusion.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Protection d'informations personnelles{" "}
            </h2>
            <p className="mt-6">
              Nous avons mis en place des mesures de sécurité physiques,
              électroniques et administratives pour protéger vos informations
              personnelles contre l'accès non autorisé, la divulgation ou
              l'utilisation abusive.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Divulgation d'informations personnelles{" "}
            </h2>
            <p className="mt-6">
              Nous ne vendons ni ne louons vos informations personnelles à des
              tiers. Cependant, nous pouvons divulguer vos informations
              personnelles si nous sommes tenus de le faire par la loi ou si
              nous croyons de bonne foi que cela est nécessaire pour protéger
              nos droits ou pour se conformer à une ordonnance de justice.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Cookies{" "}
            </h2>
            <p className="mt-6">
              Notre site web utilise des cookies pour améliorer votre expérience
              utilisateur. Un cookie est un petit fichier texte qui est stocké
              sur votre ordinateur ou votre appareil mobile lorsque vous visitez
              notre site web. Les cookies nous permettent de vous reconnaître
              lors de votre prochaine visite et de personnaliser votre
              expérience utilisateur. Vous pouvez désactiver les cookies en
              modifiant les paramètres de votre navigateur.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Modifications de la politique de confidentialité{" "}
            </h2>
            <p className="mt-6">
              Nous nous réservons le droit de modifier cette politique de
              confidentialité à tout moment en publiant une version mise à jour
              sur notre site web. Nous vous encourageons à consulter cette
              politique de confidentialité régulièrement pour prendre
              connaissance des éventuelles modifications.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Contactez-nous{" "}
            </h2>
            <p className="mt-6">
              Si vous avez des questions ou des préoccupations concernant notre
              politique de confidentialité, veuillez nous contacter à l'adresse
              e-mail suivante : contact@geerd.ma
            </p>
          </div>
        </div>
      </div>
      {/* Gradient Feature Section */}
      {/* <div className="bg-gradient-to-r from-secondary-800 to-primary-700 relative">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white tracking-tight">
            {t("features_title")}
          </h2>
          <p className="mt-4 max-w-3xl text-lg text-secondary-200">
            {t("features_subtitle")}
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map(feature => (
              <div key={feature.name[language]}>
                <div>
                  <span className="flex items-center justify-center h-16 w-16 rounded-md bg-white bg-opacity-10">
                    <feature.icon
                      className="h-12 w-12 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-white">
                    {feature.name[language]}
                  </h3>
                  <p className="mt-2 text-base text-secondary-200">
                    {feature.description[language]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* Logo Cloud */}
      {/* <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
            {t("partners")}
          </p>
          <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-3 items-center">
            <div className="col-span-1 flex justify-center">
              <OCP />
            </div>
            <div className="col-span-1 flex justify-center">
              <ABS />
            </div>
            <div className="col-span-1 flex justify-center">
              <UM6P />
            </div>
          </div>
        </div>
      </div> */}

      {/* Alternating Feature Sections */}

      {/* <Faq /> */}

      {/* CTA Section */}
      {/* <div className="bg-gradient-to-r from-secondary-200 to-primary-300">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">
              <Trans>Vous êtes à la recherche d'une offre personnalisée</Trans>
            </span>
            <span className="block bg-gradient-to-r from-secondary-600 to-primary-700 bg-clip-text text-transparent">
              <Trans>répondant à vos besoins très spécifiques</Trans> ?
            </span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="contact"
              className="flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-secondary-700 hover:to-primary-700"
            >
              <Trans>Contactez-nous</Trans>
            </Link>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
